import { Route, Routes, useLocation } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Footer from "./components/Footer";
import "./styles/styles.scss"
import Header from "./components/Header";
import useFetchMultipleFolders from "./hooks/useFetchMultipleFolders";
import Gallery from "./components/Gallery";
import illstrationIcon from "./assets/images/illustration.png"
import comicsIcon from "./assets/images/comics.png"
import apparelIcon from "./assets/images/apparel.png"
import photographyIcon from "./assets/images/photography.png"
import devonIcon from "./assets/images/devon.png"
import aboutIcon from "./assets/images/about.png"
import About from "./pages/About";
import { StripeProvider } from "./StripeProvider";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe('pk_live_51Qcx99GvpmBWDQ4Zr9UwuB17HKHWUcnOLacy5twZUkDnvpmUC0pUKM0s5QKhbMO1VFCFeFJ6yMPXkQeYh6UPFFaX00c3j1ApxE');


function App() {
  const location = useLocation()

  const navImages = [
    { name: "illustrations", link: illstrationIcon },
    // { name: "comics", link: comicsIcon },
    { name: "apparel", link: apparelIcon },
    { name: "photography", link: photographyIcon },
    { name: "about", link: aboutIcon }
  ]

  const [illustrationData, illustrationLoading, illustrationError] = useFetchMultipleFolders('illustration', ['food', 'book covers', 'personal', 'band posters', 'children books'])

  // const [comicData, comicLoading, comicError] = useFetchMultipleFolders('comics', ["bobo's cafe", "eat me", "love by lamplight", "cop-car-short", "bodega"])

  const [apparelData, apparelLoading, apparelError] = useFetchMultipleFolders('apparel', ["lookbook"])

  const [photographyData, photographyLoading, photographyError] = useFetchMultipleFolders('photography', ["photos"])

  // const [bookCoversData, bookCoversLoading, bookCoversError] = useFetchMultipleFolders("book-covers", ["art"])

  // const options = {
  //   // passing the client secret obtained from the server
  //   clientSecret: '{{CLIENT_SECRET}}',
  // };

  console.log('IllustrationData', illustrationData)
  // console.log('ComicData', comicData)


  return (
    <div className="app-root">
      {location.pathname !== "/" &&
        <Header navImages={navImages} />
      }
      <Routes>
        <Route path="/" element={<HomePage navImages={navImages} />} />
        <Route path="/illustrations" element={<Gallery key={"/illustration"} data={illustrationData} isLoading={illustrationLoading} error={illustrationError} />} />
        {/* <Route path="/comics" element={<Gallery key={"/comics"} data={comicData} isLoading={comicLoading} error={comicError} />} /> */}
        <Route path="/apparel" element={<Gallery key={"/apparel"} data={apparelData} error={apparelError} isLoading={apparelLoading} />} />
        <Route path="/photography" element={<Gallery key={"/photography"} data={photographyData} error={photographyError} isLoading={photographyLoading} />} />
        {/* <Route path="/book-covers" element={<Gallery key={"/book-covers"} data={bookCoversData} error={bookCoversError} isLoading={bookCoversLoading} />} /> */}
        <Route path="/about" element={<About devonIcon={devonIcon} />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
