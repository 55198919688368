import { useDialogAtom } from "../atoms/useDialogAtom"
import Dialog from "../components/Dialog"
import "../styles/styles.scss"
import { aboutPage } from "../utils/applicationText"

function About({ devonIcon }: Readonly<{ devonIcon: string }>) {

    const { openDialog } = useDialogAtom("Payment")

    return (
        <section className="aboutMe">
            <img alt="Devon Blue" src={devonIcon} onClick={openDialog} />
            <h4>{aboutPage.desciption}</h4>

            <Dialog atomName="Payment">
                <p>Test</p>
            </Dialog>
        </section>
    )
}

export default About
