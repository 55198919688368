import "../styles/styles.scss"
import ReactDOM from "react-dom";
import { useState, useRef, useEffect } from "react";
import { useDialogAtom } from "../atoms/useDialogAtom";

interface DialogProps {
    children: React.ReactNode;
    title?: string;
    atomName: string;
}

function Dialog({ children, title, atomName }: DialogProps) {
    const { isDialogOpen, closeDialog } = useDialogAtom(atomName)

    console.log("Dialog")

    const [isMounted, setIsMounted] = useState(false);
    const dialogRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setIsMounted(true);
        return () => {
            setIsMounted(false);
        };
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
                closeDialog();
            }
        };

        if (isDialogOpen && isMounted) {
            document.addEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isDialogOpen, isMounted, closeDialog]);


    if (!isMounted) return null;

    return ReactDOM.createPortal(
        <div className={`dialog-overlay ${isDialogOpen ? 'active' : ''}`}>
            <div className="dialog-container" ref={dialogRef}>
                <div className="dialog-header">
                    {title && <h4>{title}</h4>}
                    <button onClick={closeDialog} className="dialog-close-btn">
                        &times;
                    </button>
                </div>
                <div className="dialog-content">{children}</div>
            </div>
        </div>,
        document.body
    );
}

export default Dialog;