import { atom, useAtom } from "jotai";
import { atomFamily } from "jotai/utils";
import { useCallback } from "react";

const isDialogOpenFamily = atomFamily((_key: string) => {
    return atom(false)
});

export const useDialogAtom = (key: string) => {

    console.log("Fired")

    const [isDialogOpen, setIsDialogOpen] = useAtom(isDialogOpenFamily(key));

    // Function to open the dialog
    const openDialog = useCallback(() => {
        setIsDialogOpen(true);
        document.body.style.overflow = 'hidden';
    }, [setIsDialogOpen]);

    // Function to close the dialog
    const closeDialog = useCallback(() => {
        setIsDialogOpen(false);
        document.body.style.overflow = 'overlay';
    }, [setIsDialogOpen]);

    return { isDialogOpen, openDialog, closeDialog };
};