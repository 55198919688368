// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage';
import { getFunctions, httpsCallable } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBPJo5f2GLWxbsKaB22FEY-B7NbtHO4cqI",
    authDomain: "bluedelladraw-website.firebaseapp.com",
    projectId: "bluedelladraw-website",
    storageBucket: "bluedelladraw-website.appspot.com",
    messagingSenderId: "109583787283",
    appId: "1:109583787283:web:18f81704511ac3f55fe6e6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app)
export const functions = getFunctions(app)